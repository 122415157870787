import React, { useMemo } from 'react';
import { SimpleSelect } from 'react-selectize2';
import PropTypes from 'prop-types';

import SelectToggle from './components/select-toggle/select-toggle';
import Input from '../input/input';

import generateUID from '../../shared/utils/generate-uid';

import style from './select.scss';

export const SelectOptionProp = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.any
});

function Select(props) {
  const {
    cancelKeyboardEventOnSelection = true,
    className,
    isFlipped,
    value,
    isDisabled = false,
    id,
    isRequired,
    // Pass label if you want a placeholder that animates upward to be the label on
    // focus or when a value is selected.  If all you want is a placeholder: pass
    // the placeholder prop.  The placeholder prop and the label prop are mutually
    // exclusive.
    label = '',
    placeholder: placeHolderProp = '',
    renderOption,
    renderValue,
    shouldAutofocus,
    options = [],
    onChange = null,
    onFocus,
    filterOptions
  } = props;
  const placeholder = label ? undefined : placeHolderProp;
  const inputId = useMemo(() => id || generateUID(), [id]);
  let handleRenderOption = undefined;

  if (renderOption) {
    handleRenderOption = props =>
      renderOption({
        ...props,
        isSelected: value?.value === props.value
      });
  }

  return (
    <Input
      {...{
        ...props,
        isRequired: !placeholder && isRequired
      }}
      id={inputId}
      labelClassName={style['label']}
      styleName="input"
      type=""
    >
      <SimpleSelect
        autofocus={shouldAutofocus}
        cancelKeyboardEventOnSelection={cancelKeyboardEventOnSelection}
        className={`simple-select${
          className ? ` ${className}__select-input` : ''
        }`}
        delimiters={[9]}
        disabled={isDisabled}
        dropdownDirection={isFlipped ? -1 : 1}
        filterOptions={filterOptions}
        hideResetButton
        inputProps={{
          'aria-required': isRequired,
          id: inputId
        }}
        onFocus={onFocus}
        onValueChange={onChange}
        options={options}
        placeholder={placeholder}
        renderOption={handleRenderOption}
        renderToggleButton={SelectToggle}
        renderValue={renderValue}
        value={value}
      />
    </Input>
  );
}

Select.propTypes = {
  cancelKeyboardEventOnSelection: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: SelectOptionProp,
  error: PropTypes.shape({
    failedValidations: PropTypes.array,
    hasErrors: PropTypes.bool.isRequired,
    message: PropTypes.string
  }),
  filterOptions: PropTypes.func,
  id: PropTypes.string,
  infoMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFlipped: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  listboxId: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(SelectOptionProp),
  placeholder: PropTypes.string,
  renderOption: PropTypes.func,
  renderValue: PropTypes.func,
  shouldAutofocus: PropTypes.bool,
  value: SelectOptionProp
};

export default Select;
