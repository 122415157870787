import React from 'react';
import PropTypes from 'prop-types';

import {
  ChevronDownRegularIcon,
  ChevronUpRegularIcon,
  MinusLightIcon,
  PlusLightIcon
} from '../../../../shared/icons/icons';

import './collapsible-toggle.scss';

function CollapsibleToggle(props) {
  const {
    className,
    isOpen,
    shouldCenterToggle,
    label,
    secondary = false,
    toggleType = '',
    toggleCollapsible
  } = props;

  let Icon;

  if (toggleType === 'CHEVRON') {
    Icon = isOpen ? ChevronUpRegularIcon : ChevronDownRegularIcon;
  } else {
    Icon = isOpen ? MinusLightIcon : PlusLightIcon;
  }

  return (
    <button
      className={`collapsible-toggle ${
        className ? `${className}__heading` : null
      }`}
      onClick={toggleCollapsible}
      styleName={`${secondary ? 'secondary-' : ''}${
        shouldCenterToggle ? 'container-centered' : 'container'
      }`}
      type="button"
    >
      <span className="collapsible-toggle__label">{label}</span>
      <Icon
        className={`collapsible-toggle__icon ${
          className ? `${className}__toggle` : ''
        }`}
        styleName="icon"
      />
    </button>
  );
}

CollapsibleToggle.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  label: PropTypes.node,
  secondary: PropTypes.bool,
  shouldCenterToggle: PropTypes.bool,
  toggleCollapsible: PropTypes.func,
  toggleType: PropTypes.oneOf(['CHEVRON'])
};

export default CollapsibleToggle;
