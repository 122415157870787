import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './image.scss';

function Image(props) {
  const {
    alt,
    className,
    disableDraggable = false,
    height,
    shouldFadeIn = true,
    src,
    style,
    title,
    width
  } = props;
  const [isReadyToLoad, setIsReadyToLoad] = useState(
    document.readyState === 'complete'
  );
  const [hasLoaded, setHasLoaded] = useState(false);
  let styleName = null;
  // Make sure images load after page load for perf
  const readySrc = isReadyToLoad
    ? src
    : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  if (shouldFadeIn) {
    styleName = hasLoaded ? 'img img--loaded' : 'img';
  }

  function setReadyToLoad() {
    window.removeEventListener('load', this.setReadyToLoad);
    setIsReadyToLoad(true);
  }

  useEffect(() => {
    if (document.readyState !== 'complete') {
      window.addEventListener('load', setReadyToLoad);
    }
  });

  return (
    <img
      alt={alt}
      className={className}
      draggable={disableDraggable ? 'false' : true}
      height={height}
      onLoad={() => setHasLoaded(true)}
      src={readySrc}
      style={style}
      styleName={styleName}
      title={title}
      width={width}
    />
  );
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  disableDraggable: PropTypes.bool,
  height: PropTypes.string,
  shouldFadeIn: PropTypes.bool,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  title: PropTypes.string,
  width: PropTypes.string
};

export default memo(Image);
