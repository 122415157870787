import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../library/button/button';
import BackButton from '../../../../components/back-button/back-button';
import VehicleDescription from '../../../../components/vehicle-description/vehicle-description';
import FitmentSelect from './components/fitment-select/fitment-select';
import FitmentTireSize from './components/fitment-tire-size/fitment-tire-size';
import TabAsHeader from '../../../tab-as-header/tab-as-header';
import { CRUMBS, EVENTS, PROP_TYPES } from '../../fitment-constants';
import FitmentCrumbs from '../fitment-crumbs/fitment-crumbs';
import {
  assemblyPropType,
  modelPropType,
  rideHeightPropType,
  trimPropType
} from './fitment-by-vehicle-util';

import { useFitment, usePrevious } from '../../../../shared/hooks/hooks';

import style from './fitment-by-vehicle.scss';

const FitmentList = lazy(() =>
  import(
    /* webpackChunkName: "fitment-list" */
    '../fitment-list/fitment-list'
  )
);

const TABS = {
  RECENT: 'RECENT',
  SAVED: 'SAVED'
};

function FitmentByVehicle(props) {
  const {
    assemblies,
    textOverrides,
    hideBackButton,
    hideMyVehiclesTab,
    hideVehicleCrumbs = false,
    hideDelete,
    isEditing,
    isLoggedIn,
    isShowingRecentFitments,
    isYearAfterModel,
    canToggleVehicle,
    getOptionalSizes,
    makes,
    models,
    onBackToMyVehicles,
    onCrumbSelected,
    onEvent,
    onMakeSelected,
    onModelSelected,
    onRecentFitmentRemoved,
    onSavedFitmentRemoved,
    onFitmentsToggled,
    onFitmentSelected,
    onFocusDropdown,
    onRideHeightSelected,
    onSignInClick,
    onShopProducts,
    onSelectFitment,
    onTrimSelected,
    onYearSelected,
    recentFitments,
    renderAboveVehicleForm,
    renderFitmentEdit,
    renderSelectedFitment,
    renderShopProductsButton,
    rideHeights,
    savedFitments,
    selectedAssembly,
    selectedCrumb,
    selectedFitment,
    selectedMake,
    selectedModel,
    selectedRideHeight,
    selectedTrim,
    selectedYear,
    setSelectedAssembly,
    setIsShowingRecentFitments,
    shouldFilterOptionalOESize,
    shouldAutofocusYear,
    shouldCallShopProducts,
    showShopProductsButton,
    temporaryFitment,
    trims,
    years
  } = props;

  const [selectedTab, setSelectedTab] = useState();
  const previousSavedFitments = usePrevious(savedFitments);
  const { isLockedFitment } = useFitment();
  const yearOptions = useMemo(
    () =>
      years?.map(value => ({
        label: value.description,
        value: value.id
      })),
    [years]
  );
  const selectedYearOption = yearOptions?.find(
    yearOption => yearOption?.value === selectedYear?.id
  );
  const makeOptions = useMemo(
    () => makes?.map(value => ({ label: value, value })),
    [makes]
  );
  const selectedMakeOption = makeOptions?.find(
    makeOption => makeOption.value === selectedMake
  );
  const modelOptions = useMemo(
    () => models?.map(model => ({ label: model.description, value: model.id })),
    [models]
  );
  const selectedModelOption = modelOptions?.find(
    modelOption => modelOption.value === selectedModel?.id
  );
  const trimOptions = useMemo(
    () => trims?.map(trim => ({ label: trim.description, value: trim.id })),
    [trims]
  );
  const selectedTrimOption = trimOptions?.find(
    trimOption => trimOption.value === selectedTrim?.id
  );
  const rideHeightOptions = useMemo(
    () =>
      rideHeights?.map(rideHeight => ({
        label: rideHeight.description,
        value: rideHeight.id
      })),
    [rideHeights]
  );
  const selectedRideHeightOption = rideHeightOptions?.find(
    rideHeightOption => rideHeightOption.value === selectedRideHeight?.id
  );

  const hasRecentFitments =
    Array.isArray(recentFitments) && recentFitments.length > 0;

  const hasSavedFitments =
    Array.isArray(savedFitments) && savedFitments.length > 0;
  const isOnlyShowingRecentFitments =
    isShowingRecentFitments && (hasRecentFitments || hasSavedFitments);
  const isShowingFitmentCrumbs =
    Object.values(CRUMBS).some(crumb => crumb === selectedCrumb) &&
    (Boolean(selectedCrumb) || isEditing) &&
    !hideVehicleCrumbs &&
    (!isEditing || (!isLockedFitment && isEditing));

  const yearCrumb = {
    isActive: isYearAfterModel
      ? Boolean(selectedMake) && Boolean(selectedModel)
      : Boolean(selectedYear),
    label: CRUMBS.YEAR
  };

  const makeCrumb = {
    isActive: isYearAfterModel ? Boolean(selectedMake) : Boolean(selectedYear),
    label: CRUMBS.MAKE
  };

  const modelCrumb = {
    isActive: isYearAfterModel
      ? Boolean(selectedMake)
      : Boolean(selectedYear) && Boolean(selectedMake),
    label: CRUMBS.MODEL
  };

  const trimCrumb = {
    isActive:
      Boolean(selectedYear) && Boolean(selectedMake) && Boolean(selectedModel),
    label: CRUMBS.TRIM
  };

  const sizeCrumb = {
    isActive:
      Boolean(selectedYear) &&
      Boolean(selectedMake) &&
      Boolean(selectedModel) &&
      Boolean(selectedTrim) &&
      Boolean(selectedRideHeight),
    label: CRUMBS.TIRE_WHEEL_SIZE
  };

  const crumbs = isYearAfterModel
    ? [makeCrumb, modelCrumb, yearCrumb, trimCrumb, sizeCrumb]
    : [yearCrumb, makeCrumb, modelCrumb, trimCrumb, sizeCrumb];

  useEffect(() => {
    if (previousSavedFitments?.length < savedFitments?.length) {
      setIsShowingRecentFitments(true);
    }
  }, [savedFitments, previousSavedFitments, setIsShowingRecentFitments]);

  useEffect(() => {
    setSelectedTab(isLoggedIn && !hideMyVehiclesTab ? TABS.SAVED : TABS.RECENT);
  }, [hideMyVehiclesTab, isLoggedIn]);

  useEffect(() => {
    onEvent?.({
      name: EVENTS.VEHICLE_FITMENT_CRUMBS_SHOWING,
      payload: isShowingFitmentCrumbs && !isOnlyShowingRecentFitments
    });
  }, [isOnlyShowingRecentFitments, isShowingFitmentCrumbs, onEvent]);

  useEffect(() => {
    if (
      !selectedRideHeight &&
      Array.isArray(rideHeights) &&
      rideHeights.length === 1
    ) {
      onRideHeightSelected(rideHeights[0]);
    }
  }, [selectedRideHeight, onRideHeightSelected, rideHeights]);

  if (isOnlyShowingRecentFitments) {
    return (
      <>
        <TabAsHeader
          onTabSelect={setSelectedTab}
          selectedTab={selectedTab}
          styleName="fitment-select-tabs"
          tabs={!hideMyVehiclesTab ? [TABS.SAVED, TABS.RECENT] : []}
          trailingContent={() => (
            <Button
              onClick={() => void onFitmentsToggled(false)}
              styleName="add-vehicle-button"
            >
              add vehicle +
            </Button>
          )}
        />

        {selectedTab === TABS.SAVED && (
          <>
            {isLoggedIn && (
              <>
                {savedFitments?.length > 0 && (
                  <Suspense>
                    <FitmentList
                      canToggleVehicle={canToggleVehicle}
                      fitments={savedFitments.slice(0, 3)}
                      hideDelete={hideDelete}
                      onFitmentRemoved={onSavedFitmentRemoved}
                      onFitmentSelected={onFitmentSelected}
                      onSelectFitment={onSelectFitment}
                      selectedFitment={selectedFitment}
                    />
                    {savedFitments.length > 3 && (
                      <div styleName="show-all-container">
                        <Button
                          href="#/fitment/my-vehicles"
                          styleName="show-all-button"
                        >
                          show all saved vehicles
                        </Button>
                      </div>
                    )}
                  </Suspense>
                )}

                {!savedFitments?.length && (
                  <div styleName="vehicle-selection-text">
                    <span>Add a vehicle for better in-store experience</span>
                  </div>
                )}
              </>
            )}
            {!isLoggedIn && (
              <div styleName="vehicle-selection-text">
                <Button onClick={onSignInClick}>Create Account/Sign In</Button>{' '}
                to save vehicles from your recent searches.
              </div>
            )}
          </>
        )}

        {selectedTab === TABS.RECENT && (
          <>
            {recentFitments?.length > 0 && (
              <Suspense>
                <FitmentList
                  canToggleVehicle={canToggleVehicle}
                  fitments={recentFitments}
                  hideDelete={hideDelete}
                  onFitmentRemoved={onRecentFitmentRemoved}
                  onFitmentSelected={onFitmentSelected}
                  onSelectFitment={onSelectFitment}
                  selectedFitment={selectedFitment}
                />
              </Suspense>
            )}
            {!recentFitments?.length && (
              <div styleName="vehicle-selection-text">
                You have no recent vehicle searches
              </div>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <>
      {(hasRecentFitments || hasSavedFitments) && !hideBackButton && (
        <>
          <BackButton
            className={`${style['back-button']} fitment-by-vehicle-container__back-button`}
            onClick={() => {
              onFitmentsToggled(true);
              if (onBackToMyVehicles) {
                onBackToMyVehicles();
              }
            }}
            text={textOverrides?.backButtonLabel ?? 'back to my vehicles'}
          />
        </>
      )}

      {renderAboveVehicleForm?.()}

      {isShowingFitmentCrumbs && (
        <>
          <div
            className="fitment-by-vehicle__crumbs"
            styleName={selectedYear || selectedMake ? 'header' : 'header-empty'}
          >
            <div
              styleName={
                selectedYear || selectedMake ? 'selection-crumbs' : void 0
              }
            >
              <FitmentCrumbs
                crumbs={crumbs}
                onCrumbSelected={crumb => {
                  onCrumbSelected(crumb);
                }}
                selectedCrumb={selectedCrumb}
              />
              {(selectedYear?.description || selectedMake) && (
                <VehicleDescription
                  make={selectedMake}
                  model={selectedModel?.description}
                  rideHeight={selectedRideHeight?.description}
                  selectedFitment={{
                    chassisId: '1',
                    trimId: selectedTrim?.id,
                    vehicleId: selectedModel?.id
                  }}
                  showFitmentNotes={selectedCrumb === CRUMBS.TIRE_WHEEL_SIZE}
                  trim={selectedTrim?.description}
                  year={selectedYear?.description}
                />
              )}
            </div>
          </div>

          {(selectedYearOption || selectedMake) && <hr />}
        </>
      )}

      {selectedCrumb === CRUMBS.YEAR && (
        <FitmentSelect
          onChange={yearOption =>
            onYearSelected(years?.find(year => year?.id === yearOption?.value))
          }
          onFocus={onFocusDropdown}
          options={yearOptions}
          placeholder="Year"
          selectedOption={selectedYearOption}
          shouldAutofocus={shouldAutofocusYear}
        />
      )}

      {selectedCrumb === CRUMBS.MAKE && (
        <FitmentSelect
          onChange={makeOption => onMakeSelected(makeOption?.value)}
          onFocus={onFocusDropdown}
          options={makeOptions}
          placeholder="Make"
          selectedOption={selectedMakeOption}
        />
      )}

      {selectedCrumb === CRUMBS.MODEL && (
        <FitmentSelect
          onChange={modelOption =>
            onModelSelected(
              models?.find(model => model?.id === modelOption?.value)
            )
          }
          onFocus={onFocusDropdown}
          options={modelOptions}
          placeholder="Model"
          selectedOption={selectedModelOption}
          subject={selectedMakeOption?.label}
        />
      )}
      {selectedCrumb === CRUMBS.TRIM && (
        <div
          className="fitment-by-vehicle__trim-selection-wrapper"
          styleName="trim-selection-wrapper"
        >
          <FitmentSelect
            onChange={trimOption =>
              onTrimSelected(
                trims?.find(trim => trim?.id === trimOption?.value)
              )
            }
            onFocus={onFocusDropdown}
            options={trimOptions}
            placeholder="Trim"
            selectedOption={selectedTrimOption}
            subject={selectedModelOption?.label}
          />
          {Array.isArray(rideHeightOptions) && rideHeightOptions.length > 1 && (
            <FitmentSelect
              heading={`What is your ${selectedModelOption?.label}'s ride height?`}
              hideSubHeading
              onChange={rideHeightOption =>
                onRideHeightSelected(
                  rideHeights?.find(
                    rideHeight => rideHeight?.id === rideHeightOption?.value
                  )
                )
              }
              options={rideHeightOptions}
              placeholder="Ride Height"
              selectedOption={selectedRideHeightOption}
              subject={selectedModelOption?.label}
            />
          )}
        </div>
      )}

      {selectedCrumb === CRUMBS.TIRE_WHEEL_SIZE && (
        <FitmentTireSize
          assemblies={assemblies}
          getOptionalSizes={getOptionalSizes}
          isEditing={isEditing}
          isYearAfterModel={isYearAfterModel}
          onEvent={onEvent}
          onShopProducts={onShopProducts}
          renderFitmentEdit={renderFitmentEdit}
          renderShopProductsButton={renderShopProductsButton}
          selectedAssembly={selectedAssembly}
          selectedCrumb={selectedCrumb}
          selectedFitment={temporaryFitment || selectedFitment}
          selectedModel={selectedModel}
          selectedRideHeight={selectedRideHeight}
          selectedTrim={selectedTrim}
          selectedYear={selectedYear}
          setSelectedAssembly={setSelectedAssembly}
          shouldCallShopProducts={shouldCallShopProducts}
          shouldFilterOptionalOESize={shouldFilterOptionalOESize}
          showShopProductsButton={showShopProductsButton}
          textOverrides={textOverrides}
        />
      )}

      {selectedCrumb === '' &&
        Boolean(selectedFitment) &&
        typeof renderSelectedFitment === 'function' &&
        renderSelectedFitment()}
    </>
  );
}

FitmentByVehicle.propTypes = {
  assemblies: PropTypes.arrayOf(assemblyPropType),
  canToggleVehicle: PropTypes.bool,
  getOptionalSizes: PropTypes.func,
  hideBackButton: PropTypes.bool,
  hideDelete: PropTypes.bool,
  hideMyVehiclesTab: PropTypes.bool,
  hideVehicleCrumbs: PropTypes.bool,
  isEditing: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isShowingRecentFitments: PropTypes.bool.isRequired,
  isYearAfterModel: PropTypes.bool,
  makes: PropTypes.arrayOf(PropTypes.string),
  models: PropTypes.arrayOf(modelPropType),
  onBackToMyVehicles: PropTypes.func,
  onCrumbSelected: PropTypes.func,
  onEvent: PropTypes.func,
  onFitmentSelected: PropTypes.func,
  onFitmentsToggled: PropTypes.func,
  onFocusDropdown: PropTypes.func,
  onMakeSelected: PropTypes.func,
  onModelSelected: PropTypes.func,
  onRecentFitmentRemoved: PropTypes.func,
  onRideHeightSelected: PropTypes.func,
  onSavedFitmentRemoved: PropTypes.func,
  onSelectFitment: PropTypes.func,
  onShopProducts: PropTypes.func,
  onSignInClick: PropTypes.func,
  onTrimSelected: PropTypes.func,
  onYearSelected: PropTypes.func,
  recentFitments: PropTypes.arrayOf(PROP_TYPES.fitment),
  renderAboveVehicleForm: PropTypes.func,
  renderFitmentEdit: PropTypes.func,
  renderSelectedFitment: PropTypes.func,
  renderShopProductsButton: PropTypes.func,
  rideHeights: PropTypes.arrayOf(rideHeightPropType),
  savedFitments: PropTypes.arrayOf(PROP_TYPES.fitment),
  selectedAssembly: assemblyPropType,
  selectedCrumb: PropTypes.string,
  selectedFitment: PROP_TYPES.fitment,
  selectedMake: PropTypes.string,
  selectedModel: modelPropType,
  selectedRideHeight: rideHeightPropType,
  selectedTrim: trimPropType,
  selectedYear: PropTypes.any,
  setIsShowingRecentFitments: PropTypes.func,
  setSelectedAssembly: PropTypes.func,
  shouldAutofocusYear: PropTypes.bool,
  shouldCallShopProducts: PropTypes.bool,
  shouldFilterOptionalOESize: PropTypes.bool,
  showShopProductsButton: PropTypes.bool,
  temporaryFitment: PROP_TYPES.fitment,
  textOverrides: PropTypes.object,
  trims: PropTypes.arrayOf(trimPropType),
  years: PropTypes.any
};

export default FitmentByVehicle;
