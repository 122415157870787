import React from 'react';
import PropTypes from 'prop-types';

import {
  default as LibrarySelect,
  SelectOptionProp
} from '../../library/select/select';

function Select(props) {
  return <LibrarySelect {...props} cancelKeyboardEventOnSelection={false} />;
}

Select.propTypes = {
  cancelKeyboardEventOnSelection: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: SelectOptionProp,
  error: PropTypes.shape({
    failedValidations: PropTypes.array,
    hasErrors: PropTypes.bool.isRequired,
    message: PropTypes.string
  }),
  id: PropTypes.string,
  infoMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFlipped: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  listboxId: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(SelectOptionProp),
  placeholder: PropTypes.string,
  renderOption: PropTypes.func,
  renderValue: PropTypes.func,
  shouldAutofocus: PropTypes.bool,
  value: SelectOptionProp
};

export default Select;
