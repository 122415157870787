import { useQuery } from '@apollo/react-hooks';

import { GET_MESSAGES } from '../../graphql/messages-queries';

export default function useMessage(key, shouldSkip) {
  key = Array.isArray(key) ? key : [key];

  const { data } = useQuery(GET_MESSAGES, {
    skip: shouldSkip,
    variables: {
      keys: key
    }
  });

  return data?.configuration?.byKeys?.reduce((acc, config) => {
    acc[config.key] = config.value;
    return acc;
  }, {});
}
