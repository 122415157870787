import React from 'react';
import PropTypes from 'prop-types';

import './content.scss';

function Content(props) {
  const { children, className, isFullWidth = false, showFrame = false } = props;

  let styleName = isFullWidth ? 'full-width ' : '';
  styleName += showFrame ? 'content-frame' : 'content';

  return (
    <div className={className} data-testid="page-content" styleName={styleName}>
      {children}
    </div>
  );
}

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
  showFrame: PropTypes.bool
};

export default Content;
