import React from 'react';
import ReactLazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';

// This component allows us to disable lazy loading in a prerender.io env
const LazyLoad = props => {
  let children = props.children;

  return (
    <ReactLazyLoad
      {...props}
      offset={window.__IS_PRERENDER_IO_ENV__ ? 10000 : props.offset}
    >
      {typeof children === 'function' ? children() : children}
    </ReactLazyLoad>
  );
};

LazyLoad.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  offset: PropTypes.number
};

export default LazyLoad;
