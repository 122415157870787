import React from 'react';
import PropTypes from 'prop-types';

import Select, { SelectOptionProp } from '../../../../../select/select';
import FitmentStepContainer from '../fitment-step-container/fitment-step-container';

import './fitment-select.scss';

function FitmentSelect(props) {
  const {
    heading,
    hideSubHeading,
    onChange,
    onFocus,
    options,
    placeholder,
    selectedOption,
    shouldAutofocus,
    subject
  } = props;

  const defaultHeading = `What ${placeholder.toLowerCase()} is your ${
    subject || 'vehicle'
  }?`;

  return (
    <FitmentStepContainer
      heading={heading || defaultHeading}
      subHeading={!hideSubHeading && "Add your vehicle, and we'll get started"}
    >
      <Select
        isRequired
        label={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        options={options}
        shouldAutofocus={shouldAutofocus}
        styleName="select"
        value={selectedOption}
      />
    </FitmentStepContainer>
  );
}

FitmentSelect.propTypes = {
  heading: PropTypes.string,
  hideSubHeading: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(SelectOptionProp),
  placeholder: PropTypes.string.isRequired,
  selectedOption: SelectOptionProp,
  shouldAutofocus: PropTypes.bool,
  subject: PropTypes.string
};

export default FitmentSelect;
