import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader/root';
import { useQuery } from '@apollo/react-hooks';
import { parse } from 'query-string';

import Page, { Content, Section } from '../../library/page/page';
import FindAStoreModal from '../../components/find-a-store-modal/find-a-store-modal';
import FitmentSmartLoader from '../../components/fitment-smart-loader/fitment-smart-loader';
import DefaultLayout from '../../layouts/default-layout/default-layout';
import { ORGANIZATION_LD, WEBSITE_LD } from './home-page-constants';

import { GET_SUGGESTIONS } from '../../shared/graphql/suggestion-queries';
import { GET_HOME_PAGE } from './graphql/home-page-queries';

import { useDataLayer, useFitment, useMyStore } from '../../shared/hooks/hooks';

import { trackAction } from '../../shared/utils/analytics/analytics';
import { getVehicleInput } from '../../shared/utils/fitment';
import { getCmsSiteId, metaTags } from '../../shared/utils/page';
import { isCECSite } from '../../shared/utils/site';
import { sessionStorage } from '../../shared/utils/storage';
import { isArrayWithLength } from '../../shared/utils/validators';

import {
  PAGE_NAME,
  PAGE_SECTION_NAME,
  PAGE_SUBCATEGORY,
  PAGE_TYPE
} from '../../shared/constants/data-layer';
import { OKTA_TOKEN } from '../../shared/constants/storage-keys';

import './home-page.scss';

const SuggestionQuickLinks = lazy(() =>
  import('../../components/suggestion-quick-links/suggestion-quick-links')
);

function HomePage() {
  const { find_store: shouldOpenFindStoreModal } = parse(
    window.location.search
  );
  const { selectedFitment } = useFitment();
  const storeCode = useMyStore()?.myStore?.code;
  const { updateDataLayer } = useDataLayer({
    shouldResetPageOnFitmentChange: true
  });
  const orgLDRef = useRef(JSON.stringify(ORGANIZATION_LD));
  const websiteLDRef = useRef(JSON.stringify(WEBSITE_LD));
  const prevFitmentRef = useRef();
  const [fitmentLoaderKey, setFitmentLoaderKey] = useState(0);

  const isFindStoreModalOpen = Boolean(shouldOpenFindStoreModal);

  const { data, error } = useQuery(GET_HOME_PAGE, {
    skip: !storeCode,
    variables: {
      id: 'homepage',
      siteId: getCmsSiteId()
    }
  });
  const { data: suggestionsProductMap } = useQuery(GET_SUGGESTIONS, {
    skip: !storeCode,
    variables: {
      storeCode: storeCode,
      suggestionInput: {
        axleType: 'BOTH',
        isAccessorySearched: false,
        isTireSearched: false,
        isWheelSearched: false,
        latitude: 0,
        longitude: 0,
        page: 'DT_STORE_HOME_PAGE'
      },
      vehicleInfo: getVehicleInput(selectedFitment)
    }
  });
  const { cms } = data || {};

  const { page: cmsPage } = cms || {};
  const {
    contentSlots = [],
    documentTitle,
    metaTags: metaData
  } = cmsPage || {};

  const [bannerSection] = contentSlots;
  const bannerComponent = bannerSection?.cmsComponents[0];
  const mainHero = bannerComponent?.slides?.[0] || {};
  const cmsPageId = 'home';

  function getHeroImgAttrs(hero) {
    const img = hero?.image || {};
    const imgUrl = img.url || '';

    return {
      alt: img.altText,
      sizes: `
      (-webkit-min-device-pixel-ratio: 2) 50vw,
      (min-resolution: 192dpi) 50vw,
      (-webkit-min-device-pixel-ratio: 3) 33.33vw,
      (min-resolution: 288dpi) 33.33vw
      `,
      src: `${imgUrl}?$h256$`,
      srcset: `
        ${imgUrl}?$h450$ 1200w,
        ${imgUrl}?$h400$ 1024w,
        ${imgUrl}?$h340$ 768w,
        ${imgUrl}?$h256$ 600w
      `
    };
  }

  const mainHeroImgAttrs = getHeroImgAttrs(mainHero);

  useEffect(() => {
    if (isArrayWithLength(contentSlots)) {
      updateDataLayer({
        [PAGE_NAME]: cmsPageId,
        [PAGE_SECTION_NAME]: documentTitle,
        [PAGE_SUBCATEGORY]: cmsPageId,
        [PAGE_TYPE]: cmsPageId
      });
    }
  }, [contentSlots, documentTitle, updateDataLayer]);

  useEffect(() => {
    if (prevFitmentRef.current && !selectedFitment) {
      setFitmentLoaderKey(prevKey => prevKey + 1);
    }

    prevFitmentRef.current = selectedFitment;
  }, [selectedFitment]);

  useEffect(() => {
    function cmsContentTracking(e) {
      const linkText = e?.target?.innerText?.trim();

      if (linkText === 'use our tire guide') {
        trackAction('treadwell_use_tire_guide_click');
      } else if (linkText === 'get started') {
        trackAction('treadwell_touchless_get_started_click');
      }
    }

    document.body.addEventListener('click', cmsContentTracking);

    return () => {
      document.body.removeEventListener('click', cmsContentTracking);
    };
  }, []);

  if (error && !window.isPreRendering) {
    throw error;
  }

  return (
    <>
      {isFindStoreModalOpen && isCECSite() ? (
        <FindAStoreModal isOpen={isFindStoreModalOpen} />
      ) : (
        <DefaultLayout hideMobileStoreHeader={false}>
          <Page>
            {!window.isPreRendering && (
              <Helmet>
                {documentTitle && <title>{documentTitle}</title>}
                {metaTags(metaData)}
                <link
                  href={`${window.location.protocol}//${window.location.host}`}
                  rel="canonical"
                />
                <script type="application/ld+json">{orgLDRef.current}</script>
                <script type="application/ld+json">
                  {websiteLDRef.current}
                </script>
              </Helmet>
            )}

            {window.isPreRendering && mainHero && (
              <Helmet>
                <link
                  as="image"
                  href={mainHeroImgAttrs.src}
                  imageSizes={mainHeroImgAttrs.sizes}
                  imageSrcSet={mainHeroImgAttrs.srcset}
                  rel="preload"
                />
              </Helmet>
            )}

            <div styleName="home-hero-container" />
            <Content isFullWidth>
              {(storeCode || (isCECSite() && sessionStorage(OKTA_TOKEN))) && (
                <Section styleName="fitment-section">
                  <div styleName="fitment-container">
                    <FitmentSmartLoader
                      key={'fitment-loader-' + fitmentLoaderKey}
                      onAddVehicleClicked={() => {
                        trackAction('vehicle_page_multiple_add_vehicle');
                      }}
                      onShopProducts={() => {
                        trackAction('vehicle_page_new_vehicle_add');
                      }}
                    />
                  </div>
                </Section>
              )}
              {suggestionsProductMap?.productSearchWs?.suggestion?.products && (
                <Suspense fallback={<div />}>
                  <SuggestionQuickLinks
                    products={
                      suggestionsProductMap?.productSearchWs?.suggestion
                        ?.products
                    }
                  />
                </Suspense>
              )}
            </Content>
          </Page>
        </DefaultLayout>
      )}
    </>
  );
}

export default hot(HomePage);
