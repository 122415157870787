import React from 'react';
import PropTypes from 'prop-types';

import {
  AngleDownLightIcon,
  AngleUpLightIcon
} from '../../../../shared/icons/icons';

function SelectToggle({ open }) {
  return open ? <AngleUpLightIcon /> : <AngleDownLightIcon />;
}

SelectToggle.propTypes = {
  open: PropTypes.bool
};

export default SelectToggle;
