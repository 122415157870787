import React from 'react';
import PropTypes from 'prop-types';

import { toInches } from '../../shared/utils/text';

import './size-badge.scss';

function SizeBadge({ className, sizeQualifier, isOE }) {
  return (
    <span
      className={className}
      styleName={`size-badge ${isOE ? 'size-badge--oe' : ''}`}
    >
      {isOE
        ? 'O.E.'
        : `${parseInt(sizeQualifier) > -1 ? '+' : ''}${toInches(
            sizeQualifier
          )}`}
    </span>
  );
}

SizeBadge.propTypes = {
  className: PropTypes.string,
  isOE: PropTypes.bool,
  sizeQualifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SizeBadge;
