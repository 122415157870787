import React from 'react';
import PropTypes from 'prop-types';

import './section.scss';

export const Section = React.forwardRef(function Section(props, ref) {
  const {
    className,
    children,
    showDivider = false,
    heading,
    id,
    intro,
    isFullWidth = false
  } = props;

  return (
    <section
      className={`section ${className}`}
      id={id}
      ref={ref}
      styleName={showDivider ? 'container-w-divider' : 'container'}
    >
      {heading && <h3 styleName="heading">{heading}</h3>}

      {intro && <div styleName="intro">{intro}</div>}

      <div
        className={`section__content ${
          className ? `${className}__content` : ''
        }`}
        data-testid="section-content"
        styleName={isFullWidth ? 'content-fullwidth' : 'content'}
      >
        {children}
      </div>
    </section>
  );
});

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  heading: PropTypes.node,
  id: PropTypes.string,
  intro: PropTypes.node,
  isFullWidth: PropTypes.bool,
  showDivider: PropTypes.bool
};

export default Section;
