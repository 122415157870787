import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../button/button';

import { ChildrenProp } from '../../../../../../shared/utils/prop-types';

import './fitment-step-container.scss';

function FitmentStepContainer(props) {
  const {
    className,
    children,
    heading,
    moreInfoContent,
    moreInfoHeading,
    subHeading
  } = props;

  const [isDisplayingMoreInfo, setIsDisplayingMoreInfo] = useState(false);

  return (
    <div
      className={`fitment-step-container ${className ? ` ${className} ` : ''}`}
      styleName="container"
    >
      <h4>{heading}</h4>
      <h5>
        {subHeading}
        {typeof moreInfoHeading === 'function' && (
          <Button
            className="fitment-step-container__more-info-button"
            onClick={() => setIsDisplayingMoreInfo(current => !current)}
            styleName="more-info-button"
          >
            {moreInfoHeading()}
          </Button>
        )}
      </h5>
      {typeof moreInfoContent === 'function' && (
        <div
          styleName={
            isDisplayingMoreInfo ? 'more-info-open' : 'more-info-closed'
          }
        >
          {moreInfoContent()}
        </div>
      )}
      {children}
    </div>
  );
}

FitmentStepContainer.propTypes = {
  children: ChildrenProp,
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  moreInfoContent: PropTypes.func,
  moreInfoHeading: PropTypes.func,
  subHeading: PropTypes.string.isRequired
};

export default FitmentStepContainer;
