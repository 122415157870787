import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from './components/breadcrumbs/breadcrumbs';
import Content from './components/content/content';
import Heading from './components/heading/heading';
import Section from './components/section/section';

function Page(props) {
  const { children, className } = props;

  return (
    <div className={`page${className ? ` ${className}` : ''}`}>{children}</div>
  );
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string
};

export { Heading, Breadcrumbs, Content, Section };
export default Page;
