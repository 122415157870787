import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import CollapsibleToggle from './components/collapsible-toggle/collapsible-toggle';

import './collapsible.scss';

function Collapsible(props) {
  const {
    className,
    label,
    isOpenByDefault = false,
    onToggle,
    secondary = false,
    shouldExpandUp = false,
    shouldCenterToggle = false,
    toggleType,
    children,
    hideToggle
  } = props;
  const [isOpenInternal, setIsOpenInternal] = useState(isOpenByDefault);
  const [contentHeight, setContentHeight] = useState(0);
  const openTimerRef = useRef(null);
  const contentRef = useRef(null);
  const expandTime = 340;
  const isOpen =
    typeof props.isOpen === 'boolean' ? props.isOpen : isOpenInternal;

  function handleOpenClose(isOpen) {
    const contentHeight = contentRef?.current?.clientHeight;

    if (isOpen) {
      if (contentHeight) {
        setContentHeight(contentHeight);
      }
      openTimerRef.current = setTimeout(() => {
        setContentHeight('auto');
      }, expandTime);
    } else {
      if (openTimerRef.current) {
        clearTimeout(openTimerRef.current);
      }
      if (contentHeight) {
        setContentHeight(contentHeight);

        setTimeout(() => {
          setContentHeight(0);
        }, 20);
      }
    }
  }

  function toggleCollapsible() {
    const hasIsOpenProp = typeof props.isOpen === 'boolean';

    if (typeof onToggle === 'function') {
      if (hasIsOpenProp) {
        onToggle(!props.isOpen);
      } else {
        onToggle(!isOpenInternal);
      }
    }

    if (!hasIsOpenProp) {
      setIsOpenInternal(!isOpenInternal);
    }
  }

  useEffect(() => {
    if (typeof props.isOpen !== 'boolean') {
      handleOpenClose(isOpenInternal);
    }
  }, [isOpenInternal, props.isOpen]);

  useEffect(() => {
    if (typeof props.isOpen === 'boolean') {
      handleOpenClose(props.isOpen);
    }
  }, [props.isOpen]);

  return (
    <div className={`collapsible ${className}`} styleName="container">
      {!shouldExpandUp && !hideToggle && (
        <CollapsibleToggle
          className={className}
          isOpen={isOpen}
          label={label}
          secondary={secondary}
          shouldCenterToggle={shouldCenterToggle}
          toggleCollapsible={toggleCollapsible}
          toggleType={toggleType}
        />
      )}
      <div
        className={`collapsible__content-wrapper ${
          className ? `${className}__content-wrapper` : null
        }`}
        style={{
          height: contentHeight
        }}
        styleName="content-wrapper"
      >
        <div
          className={`collapsible__content ${
            className ? `${className}__content` : ''
          }`}
          ref={contentRef}
          styleName={secondary ? undefined : 'content'}
        >
          {children}
        </div>
      </div>
      {shouldExpandUp && !hideToggle && (
        <CollapsibleToggle
          className={className}
          isOpen={isOpen}
          label={label}
          shouldCenterToggle={shouldCenterToggle}
          toggleCollapsible={toggleCollapsible}
        />
      )}
    </div>
  );
}

Collapsible.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  hideToggle: PropTypes.bool,
  isOpen: PropTypes.bool,
  isOpenByDefault: PropTypes.bool,
  label: PropTypes.node,
  onToggle: PropTypes.func,
  secondary: PropTypes.bool,
  shouldCenterToggle: PropTypes.bool,
  shouldExpandUp: PropTypes.bool,
  toggleType: PropTypes.string
};

export { CollapsibleToggle };
export default Collapsible;
