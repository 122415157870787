import React, { lazy, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import LazyLoad from '../../components/lazy-load';
import MobileHeaderStoreView from '../../components/mobile-header-store-view/mobile-header-store-view';
import Header from '../components/header/header';

import { GET_LAYOUT } from './graphql/default-layout-queries';

import { useMyStore } from '../../shared/hooks/hooks';

import { isSimplifiedHeaderPath } from '../../shared/utils/header';
import { getCmsSiteId } from '../../shared/utils/page';
import { isCECSite } from '../../shared/utils/site';

import './default-layout.scss';

const Footer = lazy(() =>
  import(
    /* webpackChunkName: "footer" */
    '../components/footer/footer'
  )
);

const DefaultLayout = props => {
  const {
    children,
    hideFooter = false,
    hideMobileStoreHeader = true,
    hideMyAccountHeaderLink
  } = props;
  const storeCode = useMyStore()?.myStore?.code;

  const { data, error } = useQuery(GET_LAYOUT, {
    errorPolicy: 'all',
    skip: !storeCode,
    variables: {
      siteId: getCmsSiteId()
    }
  });
  const { cms } = data || {};
  const { globalHeader, globalFooter } = cms || {};
  const { copyright, footerZone2, primaryLinks, secondaryLinks, socialLinks } =
    globalFooter || {};

  const placeholder = (
    <footer style={{ background: '#282a2b', minHeight: 550 }} />
  );

  const location = useLocation();

  if (error) {
    console.error('Error loading default layout query', error);
  }

  useEffect(() => {
    window.hasShownInitialStoreDropdown = true;
  }, []);

  return (
    <>
      <Header
        hideMyAccountLink={hideMyAccountHeaderLink}
        linkCategories={globalHeader?.navigationNodes}
        showInitialStoreDropdown={
          window.hasShownInitialStoreDropdown === undefined ? true : false
        }
      />
      <main
        id="main"
        styleName={`main ${
          isCECSite() && !isSimplifiedHeaderPath(location.pathname)
            ? 'main--extended-nav-links'
            : ''
        }`}
      >
        {!hideMobileStoreHeader && <MobileHeaderStoreView />}
        {children}
      </main>
      {!hideFooter && (
        <LazyLoad placeholder={placeholder}>
          <Suspense fallback={placeholder}>
            <Footer
              copyright={copyright}
              footerZone2={footerZone2}
              primaryLinks={primaryLinks}
              secondaryLinks={secondaryLinks}
              socialLinks={socialLinks}
            />
          </Suspense>
        </LazyLoad>
      )}
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  hideFeedbackButton: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideMobileStoreHeader: PropTypes.bool,
  hideMyAccountHeaderLink: PropTypes.bool
};

export default DefaultLayout;
